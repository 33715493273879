/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ol: "ol",
    li: "li",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Beckham Family's Luxurious Experience on the Riva 130 Bellissima"), "\n", React.createElement(_components.p, null, "I encountered an issue accessing the detailed content from the provided URL to gather information about the Beckham family's experience on the Riva 130 Bellissima. However, based on the search results, it's clear that David and Victoria Beckham, along with their family, spent Easter on a luxurious superyacht named Riva 130 Bellissima. This yacht is noted for its grandeur, significantly surpassing their previous yacht in terms of luxury and price, with the new vessel being worth £16 million compared to the £5 million valuation of their former yacht."), "\n", React.createElement(_components.p, null, "For more comprehensive details, I recommend visiting the following sources which discuss the Beckham family's lavish day at sea:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Robb Report"), ": ", React.createElement(_components.a, {
    href: "https://robbreport.com/motors/marine/david-victoria-beckham-easter-yacht-1235566235/"
  }, "David and Victoria Beckham Spent Easter on a $20 Million Superyacht")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "YTech News"), ": ", React.createElement(_components.a, {
    href: "https://ytech.news/en/beckham-family-enjoys-lavish-day-at-sea-on-riva-130-bellissima/"
  }, "Beckham Family Enjoys Lavish Day At Sea on Riva 130 Bellissima")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Luxury Launches"), ": ", React.createElement(_components.a, {
    href: "https://luxurylaunches.com/celebrities/03-31-2024-david-beckham-yacht.php"
  }, "Cashing in on his Inter Miami riches, David Beckham just upgraded...")), "\n"), "\n", React.createElement(_components.p, null, "These articles provide insights into the Beckham family's time aboard the Riva 130 Bellissima, highlighting the yacht's luxury features and the family's enjoyment of their sea outing."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
